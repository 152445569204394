<template>
  <v-container class="general">
    <page-title :component="'AppPageList'">
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeStep !== 'done'"
          ref="languageSwitcher"
          :events="true"
          @click="changeNext('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <!-- Steps -->
    <AppStepperHeader v-model="stepper" :steps="steps" :loading="isLoading" />
    <v-window v-model="stepper" class="mb-5 no-transition">
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component :is="step.component" />
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <div v-if="stepper < steps.length - 1">
      <v-btn
        round
        large
        class="ml-0 mr-3 white sw-accent text-none"
        @click="closeWizard"
      >
        {{ $t("common.cancel") }}
      </v-btn>
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext('close')"
      >
        {{ $t("common.save_and_close") }}
      </v-btn>
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext()"
      >
        {{ nextButtonText }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import Content from "@/components/AppPageWizard/Content.vue";
import Menu from "@/components/AppPageWizard/Menu.vue";
import Done from "@/components/AppPageWizard/Done.vue";
import moment from "moment-timezone";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupMenuId() {
      return this.$route.params.menu_id;
    },
    groupArticleId() {
      return this.$route.params.article_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    groupArticle: {
      get() {
        return this.$store.getters.appPageWizardArticle;
      },
      set(val) {
        this.$store.dispatch("setAppPageWizardArticle", val);
      },
    },
    menu: {
      get() {
        return this.$store.getters.appPageWizardMenu;
      },
      set(val) {
        this.$store.dispatch("setAppPageWizardMenu", val);
      },
    },
    destination() {
      if (!this.menu || !this.menu.destinations) {
        return;
      }
      return this.menu.destinations.find((el) => el.editable);
    },
    steps() {
      return [
        {
          name: "content",
          title: this.$t("app_page_wizard.content_title"),
          component: Content,
          enabled: true,
        },
        {
          name: "menu",
          title: this.$t("app_page_wizard.menu_title"),
          component: Menu,
          enabled: true,
        },
        {
          name: "done",
          title: this.$t("app_page_wizard.done_title"),
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
    nextButtonText() {
      if (this.activeStep !== "menu") {
        return this.$t("common.continue");
      }

      return this.$t("common.save");
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  beforeCreate() {
    this.groupArticle = {
      body: "<p></p>",
      publish_at: null,
      publish_until: null,
      article_title_alignment: "left",
      article_title_visibility: true,
    };
    this.menu = {
      destinations: [],
    };
    this.showInMenu = false;
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler() {
        this.fetchAll();
      },
    },
    activeStep: {
      immediate: true,
      handler(newVal) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        if (newVal !== "done") return;

        this.$store.dispatch("addNotification", {
          message: this.$t("app_page_wizard.created_successfully"),
        });
      },
    },
  },
  methods: {
    stepClass(i) {
      return [
        "pa-3 text-center step",
        {
          complete: i < this.stepper,
          editable: i < this.stepper && this.activeStep !== "done",
          active: i === this.stepper,
        },
      ];
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getGroupArticle();
        await this.getGroupMenu();

        this.isLoading = false;
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
        this.isLoading = false;
      }
    },
    async getGroupArticle() {
      if (!this.groupArticleId) return;

      const params = [
        this.groupId,
        this.groupArticleId,
        { with_attributes: 1, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupArticles.get(...params);

      this.groupArticle = {
        ...response.data.data,
        article_title_alignment:
          response.data.data.attributes.article_title_alignment,
        // if null, set to true
        article_title_visibility:
          response.data.data.attributes.article_title_visibility ||
          response.data.data.attributes.article_title_visibility == null,
      };
    },
    async getGroupMenu() {
      const params = [
        this.groupId,
        this.groupMenuId,
        { with_disabled: 1, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupMenus.get(...params);

      const menu = response.data.data;

      const destination = menu.destinations.find(
        (el) =>
          el.component === "ArticlePage" &&
          JSON.parse(el.params).article_id == this.groupArticleId,
      );

      if (!destination || !destination.id) {
        menu.destinations.push({
          editable: true,
          name: this.groupArticle.name,
          description: "",
          icon: "fas question",
          is_public: null,
          enabled: 1,
          promoted: 0,
        });
      } else {
        destination.editable = true;
      }

      this.menu = menu;
    },
    transformDateForSave(date) {
      return date ? moment(date).format("YYYY-MM-DDTHH:mm:ssZ") : null;
    },
    async createGroupArticle() {
      const specs = [
        this.groupId,
        {
          name: this.groupArticle.name,
          body: this.groupArticle.body,
          publish_at: this.transformDateForSave(this.groupArticle.publish_at),
          publish_until: this.transformDateForSave(
            this.groupArticle.publish_until,
          ),
          lang: this.appContentLanguage,
          article_title_alignment: this.groupArticle.article_title_alignment,
          article_title_visibility: this.groupArticle.article_title_visibility,
        },
      ];

      const response = await this.$api.groupArticles.create(...specs);

      this.$router.push({
        name: "app_page_wizard_edit",
        params: {
          article_id: response.data.data.id,
        },
      });

      this.destination.name = this.groupArticle.name;
    },
    async updateGroupArticle() {
      const specs = [
        this.groupId,
        this.groupArticleId,
        {
          name: this.groupArticle.name,
          body: this.groupArticle.body,
          publish_at: this.transformDateForSave(this.groupArticle.publish_at),
          publish_until: this.transformDateForSave(
            this.groupArticle.publish_until,
          ),
          lang: this.appContentLanguage,
          article_title_alignment: this.groupArticle.article_title_alignment,
          article_title_visibility: this.groupArticle.article_title_visibility,
        },
      ];

      await this.$api.groupArticles.update(...specs);
    },
    async createGroupMenuDestination() {
      const specs = [
        this.groupId,
        this.menu.id,
        {
          name: this.destination.name,
          description: this.destination.description,
          icon: this.destination.icon,
          component: "ArticlePage",
          params: JSON.stringify({ article_id: this.groupArticleId }),
          active_from: this.transformDateForSave(this.groupArticle.publish_at),
          active_till: this.transformDateForSave(
            this.groupArticle.publish_until,
          ),
          is_public: this.destination.is_public,
          lang: this.appContentLanguage,
          enabled: this.destination.enabled ? 1 : 0,
          promoted: this.destination.promoted ? 1 : 0,
        },
      ];

      const response = await this.$api.groupMenuDestinations.create(...specs);

      const destination = this.menu.destinations.find((el) => el.editable);

      destination.id = response.data.data.id;
    },
    async updateGroupMenuDestination() {
      const specs = [
        this.groupId,
        this.menu.id,
        this.destination.id,
        {
          name: this.destination.name,
          description: this.destination.description,
          icon: this.destination.icon,
          params: JSON.stringify({ article_id: this.groupArticleId }),
          active_from: this.transformDateForSave(this.groupArticle.publish_at),
          active_till: this.transformDateForSave(
            this.groupArticle.publish_until,
          ),
          is_public: this.destination.is_public,
          lang: this.appContentLanguage,
          enabled: this.destination.enabled ? 1 : 0,
          promoted: this.destination.promoted ? 1 : 0,
        },
      ];

      await this.$api.groupMenuDestinations.update(...specs);
    },
    async reorderGroupMenuDestinations() {
      let sortOrder = {};

      for (const [i, destination] of this.menu.destinations.entries()) {
        sortOrder[destination.id] = i;
      }

      const params = [
        this.groupId,
        this.menu.id,
        {
          order: sortOrder,
        },
      ];

      await this.$api.groupMenuDestinations.reorder(...params);
    },
    closeWizard() {
      this.$router.push(
        {
          name: "app_page_edit",
          params: {
            menu_id: this.menu.id,
          },
        },
        () => {},
      );
    },
    async changeNext(action) {
      if (this.activeStep === "content") {
        const isValid = await this.$validator.validateAll("content");

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.groupArticleId) {
            await this.createGroupArticle();
          } else {
            await this.updateGroupArticle();
          }

          if (!this.destination || !this.destination.id) {
            await this.createGroupMenuDestination();
          } else {
            await this.updateGroupMenuDestination();
          }

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }

      if (this.activeStep === "menu") {
        const isValid = await this.$validator.validateAll("menu");

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.destination || !this.destination.id) {
            await this.createGroupMenuDestination();
          } else {
            await this.updateGroupMenuDestination();
          }

          await this.reorderGroupMenuDestinations();

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (action === "close") {
        this.closeWizard();
        return;
      }

      if (action === "stay") {
        this.$refs.languageSwitcher.open();
        return;
      }

      this.stepper += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
