<template>
  <v-layout row wrap class="text-center">
    <v-flex xs12 class="mb-5">
      <div class="sw-h3 font-weight-light">
        {{ $t("app_page_wizard.created_successfully") }}
      </div>
    </v-flex>
    <v-flex xs12>
      <v-btn
        round
        large
        class="ma-0 white sw-accent text-none"
        @click="goToListView()"
      >
        {{ $t("app_page_wizard.go_to_list_view") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  computed: {
    menu() {
      return this.$store.getters.appPageWizardMenu;
    },
  },
  methods: {
    goToListView() {
      this.$router.push({
        name: "app_page_edit",
        params: { menu_id: this.menu.id },
      });
    },
  },
};
</script>
