<template>
  <div class="app-page-wizard-menu">
    <template v-if="destination">
      <TranslatableTooltip
        :content="$t('app_page_wizard.show_in_menu_tooltip')"
      >
        <v-switch
          v-model="destination.enabled"
          :disabled="!menu || !menu.id"
          class="my-0"
        >
          <template slot="label">
            <div class="black--text">
              {{ $t("app_page_wizard.show_in_menu") }}
            </div>
          </template>
        </v-switch>
      </TranslatableTooltip>
      <v-form v-if="destination.enabled" data-vv-scope="menu">
        <v-layout row wrap class="pt-4">
          <v-flex xs7 class="pr-5">
            <v-layout row wrap>
              <v-flex xs12 class="mb-5">
                <div class="sw-h5">
                  {{ $t("app_page_wizard.name_in_menu") }}
                </div>
                <v-text-field
                  v-model="destination.name"
                  v-validate.disable="'required'"
                  data-vv-name="name"
                  :data-vv-as="$t('app_page_wizard.name_in_menu')"
                  :placeholder="$t('app_page_wizard.type_name_here')"
                  hide-details
                ></v-text-field>
                <ErrorMessages :error-messages="errors.collect('menu.name')" />
              </v-flex>
              <v-flex xs12 class="mb-5">
                <TranslatableTooltip
                  :content="$t('app_page_wizard.menu_icon_tooltip')"
                >
                  <div class="mb-4 sw-h5">
                    {{ $t("app_page_wizard.menu_icon") }}
                  </div>
                </TranslatableTooltip>
                <IconSelector
                  v-model="destination.icon"
                  :label="$t('app_page_wizard.choose_page_icon')"
                  class="mb-3"
                />
              </v-flex>
              <v-flex xs12>
                <div class="mb-4 sw-h5">
                  {{ $t("app_page_wizard.promoted_menu_item") }}
                </div>
              </v-flex>
              <v-flex xs12 mb-4>
                <TranslatableTooltip
                  :content="
                    $t('app_page_wizard.promoted_menu_item_switch_tooltip')
                  "
                >
                  <v-switch v-model="destination.promoted" class="my-0">
                    <template slot="label">
                      <div class="black--text">
                        {{
                          destination.promoted
                            ? $t("common.yes")
                            : $t("common.no")
                        }}
                      </div>
                    </template>
                  </v-switch>
                </TranslatableTooltip>
              </v-flex>
              <v-flex xs12>
                <div class="mb-4 sw-h5">
                  {{ $t("app_page_wizard.visibility") }}
                </div>
              </v-flex>
              <v-flex xs12 class="mb-5">
                <v-radio-group
                  v-model="destination.is_public"
                  row
                  class="ma-0"
                  hide-details
                >
                  <v-layout row wrap>
                    <v-flex xs4 class="pr-4">
                      <v-radio :value="null">
                        <template slot="label">
                          <div class="black--text">
                            {{ $t("app_page_wizard.visibility_all") }}
                          </div>
                        </template>
                      </v-radio>
                      <div class="caption pt-3 pl-4 ml-2">
                        {{ $t("app_page_wizard.visibility_all_text") }}
                      </div>
                    </v-flex>
                    <v-flex xs4 class="px-4">
                      <v-radio :value="false">
                        <template slot="label">
                          <div class="black--text">
                            {{ $t("app_page_wizard.visibility_authorized") }}
                          </div>
                        </template>
                      </v-radio>
                      <div class="caption pt-3 pl-4 ml-2">
                        {{ $t("app_page_wizard.visibility_authorized_text") }}
                      </div>
                    </v-flex>
                    <v-flex xs4 class="pl-4">
                      <v-radio :value="true">
                        <template slot="label">
                          <div class="black--text">
                            {{ $t("app_page_wizard.visibility_unauthorized") }}
                          </div>
                        </template>
                      </v-radio>
                      <div class="caption pt-3 pl-4 ml-2">
                        {{ $t("app_page_wizard.visibility_unauthorized_text") }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs5 class="pl-5">
            <v-layout row wrap>
              <v-flex xs8>
                <TranslatableTooltip
                  :content="$t('app_page_wizard.place_page_in_menu_tooltip')"
                >
                  <div class="mb-4 sw-h5">
                    {{ $t("app_page_wizard.place_page_in_menu") }}
                  </div>
                </TranslatableTooltip>
                <div class="menu-draggable px-4">
                  <vue-draggable
                    v-model="menu.destinations"
                    :handle="'.draggable-handle'"
                  >
                    <div
                      v-for="(destination, i) in menu.destinations"
                      :key="`destination-${i}`"
                      id="vue-draggable"
                      class="menu-items"
                    >
                      <v-layout row class="py-4">
                        <v-flex xs2 class="text-center">
                          <font-awesome-icon
                            v-if="destination.editable"
                            icon="grip-vertical"
                            class="draggable-handle sw-accent"
                          ></font-awesome-icon>
                        </v-flex>
                        <v-flex xs2 class="text-center">
                          <font-awesome-icon
                            v-if="destination.icon"
                            :icon="transformIcon(destination.icon)"
                            class="sw-accent"
                          ></font-awesome-icon>
                        </v-flex>
                        <v-flex xs8>
                          <div class="sw-body-1 name">
                            {{ destination.name || "New page name" }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </vue-draggable>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </template>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  inject: ["parentValidator"],
  computed: {
    menu: {
      get() {
        return this.$store.getters.appPageWizardMenu;
      },
      set(val) {
        this.$store.dispatch("setAppPageWizardMenu", val);
      },
    },
    destination() {
      if (!this.menu || !this.menu.destinations) {
        return;
      }
      return this.menu.destinations.find((el) => el.editable);
    },
    groupArticle() {
      return this.$store.getters.appPageWizardArticle;
    },
  },
  components: {
    "vue-draggable": draggable,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    transformIcon(icon) {
      return icon ? icon.split(" ") : [];
    },
  },
};
</script>

<style lang="scss">
.app-page-wizard-menu {
  .menu-draggable {
    border: 1px solid $accent;
  }
  .menu-items:not(:last-child) {
    border-bottom: 1px solid $accent;
  }
  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-input--radio-group .v-input__control {
    width: 100%;
  }
}
</style>
